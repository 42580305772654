




@import url('https://fonts.googleapis.com/css2?family=Abel&display=swap');

*{
  margin: 0;
  padding: 0;
  font-family: "Abel", sans-serif;
}

.App {
  text-align: center;
  background-color: white;
  width: 100%;


}



.banner{
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.banner__video{
  width: 100%;
}

.banner__video-gif{
  width: 100%;
}
.banner__title{
  width: 100%;
  color: white;

  font-style: bold;
  font-size: 100px;
  font-weight: 800;
}

.banner__subtitle{
  width: 100%;
  color: white;
  font-size: 50px;
}

@media screen and (max-width:867px) {

  .banner__subtitle{
    font-size: 20px;
  }
  
}

@media screen and (max-width:867px) {
  .banner__title{
    font-size: 20px;
  }
  
}

.banner__title-container{
  position: absolute;
}

.header{
  width: 100%;
  display: grid;
  grid-template-columns: 30% 70%;
  border-bottom: 0.09px rgb(193, 191, 191) solid;
}

@media screen and (max-width:767px) {

  .header{
    grid-template-columns: auto;
  }
  
}

.header__list-container{
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-template-rows: 1fr;
  list-style: none;
  align-items: center;
}
.header__list {
  color: black;
  font-size: 35px;
  display: flex;
  align-items: center;
  box-shadow: transparent;
  user-select: none;
  text-shadow: none;
  transition: color 1s ease;
  font-style: normal;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
  font-weight: bold;
}

.header__link{
  text-decoration: none;
}

.header__list:before,
.header__list:after,
.header__list span:before,
.header__list span:after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  background: linear-gradient(to right, green, #4CAF50);
  transition: all 1s ease;
  z-index: -1;
}

.header__list:before,
.header__list:after {
  height: 0;
  width: 2px;
  background: linear-gradient(to bottom, green, #4CAF50);
}

.header__list:before {
  left: 0;
  bottom: 0;
  transition-delay: 0.1s;
}

.header__list:after {
  right: 0;
  top: 0;
  transition-delay: 0.3s;
}

.header__list span:before {
  top: 0;
  left: 0;
  transition-delay: 0.2s;
}

.header__list span:after {
  bottom: 0;
  right: 0;
  transition-delay: 0.4s;
}

.header__list:hover:before,
.header__list:hover:after,
.header__list:hover span:before,
.header__list:hover span:after {
  width: 100%;
  height: 100%;
}




@media screen and (max-width:867px) {

  .header__list{
    font-size: 16px;
  }
  
}

.header__logo-container{
  width: 100%;
}

.header__logo{
  width: 40%;
}

.offer{
  width: 100%;
  padding-top: 50px;

  padding-bottom: 50px;
}

.offer__title{

  font-style: normal;
  padding-bottom: 50px;
  font-size: 50px;
}

@media screen and (max-width:867px) {

  .offer__title{
    font-size: 30px;
  }
  
}

.offer__subtitle{
  font-size: 26px;
  font-style: normal;
}
.offer__text{
  font-size: 30px;
  font-style: normal;
  font-weight: 100;
}

@media screen and (max-width:867px) {
  .offer__text{
    font-size: 20px;
  }
  
}

.offer__container{
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto auto;
  gap: 25px;
}

@media screen and (max-width:867px) {
  .offer__container{
    grid-template-columns: auto;
    grid-template-rows: auto;
    padding-top: 15px;
  }
  
}

.offer__text-container{
  display: grid;
  grid-template-rows: auto auto;
}

.offer__picture{
  width: 100%;
}


.process{
 width: 100%;
 height: 100%;
 padding-bottom: 100px;


}


.process__container {
  background-image: url("./images/offer7.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: auto;

  padding-bottom: 50px;

  position: relative;
}



.process__overlay {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9); /* Capa oscura con 50% de opacidad */
  z-index: 1; /* Coloca la capa oscura por encima del fondo */
}

.process__content {
  position: relative;
  z-index: 2; /* Coloca el contenido por encima de la capa oscura */
  width: 100%;
  display: grid;
  grid-template-columns: 25% 50% 25%;
}

@media screen and (max-width:867px) {
  .process__content{
    grid-template-columns: auto auto auto;
  }
  
}

.process__pictures-container {
  width: 100%;
  position: relative;
  
}

.process__image {
  width: 100%;
}

.process__title-container {
  width: 100%;

}

.process__title {
  color: white;
  text-align: center;
  font-size: 50px;
  padding-bottom: 50px;
}

@media screen and (max-width:867px) {
  .process__title{
    font-size: 25px;
  }
  
}

.process__back-container, 
.process__next-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.process__back, 
.process__next {
  width: 25%;
  cursor: pointer;
}

@media screen and (max-width:867px) {
  .process__back, .process__next{
    width: 50%;
  }
  
}

.process__picture-container-text {
  width: 50%;
  height: 50%;
  background-color: #af997f;
  position: absolute;
  top: 50%; /* Lo coloca en la mitad superior del contenedor */
  left: 50%; /* Lo coloca en la mitad izquierda del contenedor */
  transform: translate(-50%, -50%); /* Lo centra */
  display: flex;
  right: 0;
  justify-content: center;
  align-items: center;  
  cursor: pointer;
  transition: opacity 2s ease;
  z-index: 1;
}

@media screen and (max-width:867px) {
  .process__picture-container-text{

    width: 100%;
    height: 80%;
    top:70%;
  
  }
  
}

.process__picture-container-text:before,
.process__picture-container-text:after,
.process__picture-container-text span:before,
.process__picture-container-text span:after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  background: linear-gradient(to right, green, #4CAF50);
  transition: all 2s ease;
  z-index: -1;
}

.process__picture-container-text:before,
.process__picture-container-text:after {
  height: 0;
  width: 2px;
  background: linear-gradient(to bottom, green, #4CAF50);
}

.process__picture-container-text:before {
  left: 0;
  bottom: 0;
  transition-delay: 0.1s;
}

.process__picture-container-text:after {
  right: 0;
  top: 0;
  transition-delay: 0.3s;
}

.process__picture-container-text span:before {
  top: 0;
  left: 0;
  transition-delay: 0.2s;
}

.process__picture-container-text span:after {
  bottom: 0;
  right: 0;
  transition-delay: 0.4s;
}

.process__picture-container-text:hover:before,
.process__picture-container-text:hover:after,
.process__picture-container-text:hover span:before,
.process__picture-container-text:hover span:after {
  width: 100%;
  height: 100%;
}

.process__picture-container-text:hover {
  opacity: 1; /* Hace visible el contenedor al hacer hover */
}

.process__picture-text{
  color: white;
  font-weight: bold;
  font-size: 25px;
  text-align: center;
  animation: grow 3s ease-in-out infinite; /* Animación en loop */
  display: inline-block;
  transform-origin: center; 
}

@media screen and (max-width:867px) {

  .process__picture-text{
    font-size: 16px;
  }
  
}

@keyframes grow {
  0%, 100% {
      transform: scale(1);
  }
  50% {
      transform: scale(1.1);
  }
}

.work__cards-container{
  width: 100%;
  display: grid;
grid-template-rows: auto auto;
}

@media screen and (max-width:867px) {
  .work__cards-container{
    width: 80%;
    margin: 0 auto;
  }
  
}


.work{
  width: 80%;
  margin: 0 auto;
}

.work__main-container{
  width: 100%;
}


.works__container {
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 100px 0;
  gap: 50px;
  margin: 0 auto;

}

@media screen and (max-width: 867px) {
  .works__container {
    grid-template-columns: 1fr;

  }
}

.work__title{
  font-size: 60px;
  font-weight: bold;
}

@media screen and (max-width:867px) {

  .work__title{
    font-size: 40px;
  }
  
}

.work__cards-img{
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 1; /* Asegura que el contenido esté por encima del pseudo-elemento */

}



.work__cards-text{
  font-size: 30px;
}

.work__cards {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  padding: 5px;
  z-index: 1; /* Asegura que el contenido esté por encima del pseudo-elemento */
}



.work__cards-subtitle:hover {
  color: #4CAF50; /* Cambia el color al pasar el ratón por encima (opcional) */
}


.work__subtitle{
  font-size: 20px;
  padding: 15px 0 15px 0;
  font-weight: bold;
  animation: workSubtitleSize 2s ease-in-out infinite;
}

@keyframes workSubtitleSize {

  0%{
    font-size: 22px;


  }
  40%{
    font-size: 24px;

  }
  60%{
    font-size: 24px;
 
  }
  100%{
    font-size: 22px;

  }
  
}

.work__cards::before {
  content: "";
  background: linear-gradient(#24C6DC, #514A9D);
  width: 150px;
  height: 120%;
  position: absolute;
  z-index: -1; /* Coloca el pseudo-elemento detrás del contenido */
  animation: rotate 30s linear infinite;
}

.work__cards::after {
  content: "";
  position: absolute;
  inset: 5px;
  z-index: 0; /* Mantiene este pseudo-elemento detrás del contenido principal */
}

@keyframes rotate {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}

.work__cards-hover-text{
  position: absolute; /* Lo posiciona en relación al contenedor */
  width: 100%;
  height: 100%;

  text-align: center;

  color: white;
  z-index: 1;
  font-size: 30px;
  font-weight: bold;
  display: flex;
  align-items: center;
  cursor: pointer;
  opacity: 0;
  
}

@media screen and (max-width:867px) {
  .work__cards-hover-text{
    font-size: 16px;
    text-align: center;
  }
  
}


.work__cards:hover .work__cards-hover-text {
  opacity: 1; /* Hace visible el texto al hacer hover sobre el contenedor */
  background-color: #4CAF50; /* Cambia el color de fondo al hacer hover */
  color: #fff; /* Cambia el color del texto al hacer hover */
  width: 100%;
  height: 100%;
  transition: background-color 0.9s ease;

}

.contact{
  width: 100%;
  background-color: black;
}


.contact__title{
  font-size: 70px;
  font-weight: normal;
  color: white;
  font-weight: bold;
}

@media screen and (max-width:867px) {
  .contact__title{
    font-size: 35px;
  }
  
}
.contact__media-container{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 50px;
}


@media screen and (max-width:867px) {
  .contact__media-img{
    width: 40%;
  }
  
}

.contact__media-content{
  width: 100%;
  background-color: white;
  padding: 50px 0 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact__media-cards-container{

  width: 25%;
  height: 100%; 

  position: relative;



} 

.contact__media-card{
  width: 30%  ;
}

@media screen and (max-width:867px) {
  .contact__media-card{
    width: 50%;
  }
  
}



.contact__media-cards-text{
  text-align: center;
  font-size: 25px;
  color: white;
  font-weight: bold;
  position: absolute;
  top: 50%; /* Lo coloca en la mitad superior del contenedor */
  left: 50%; /* Lo coloca en la mitad izquierda del contenedor */
  transform: translate(-50%, -50%); /* Lo centra */
  opacity: 0; /* Lo hace invisible por defecto */
  transition: opacity 0.3s; /* Añade una transición suave */
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width:867px) {
  .contact__media-cards-text{
    font-size: 12px;
  }
  
}

.contact__media-cards-text:hover{
  opacity: 1; /* Hace visible el texto al hacer hover sobre el contenedor */
  background-color: #4CAF50; /* Cambia el color de fondo al hacer hover */
  color: #fff; /* Cambia el color del texto al hacer hover */
  width: 50%;
  height: 100%;
  transition: background-color 0.9s ease;
}

.contact__media{
  width: 100%;
}

.contact__media-title{
  color: white;
  text-align: center;
  font-size: 25px;
}

.contact__link{
  cursor: pointer;
}

@media screen and (max-width:867px) {

  .contact__media-title{
    font-size: 11px;
  }

  
}
@media screen and (max-width:867px) {

  iframe{
    width: 100%;
    height: 300px;
  }
  
}


.footer {
  width: 100%;
  position: relative;

}

.footer__container {
  background-image: url("./images/footer.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  gap: 35px;

}

.footer__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.7);

}

.footer__title {
  font-size: 50px;

  position: relative;
  color: white; /* Ensure the text is visible over the dark overlay */
}

@media screen and (max-width:867px) {

  .footer__title{
    font-size: 30px;
  }
  
}

.footer__subtitle{
  color: white;
  position: relative;

}

@media screen and (max-width:867px) {

  .footer__subtitle{
    font-size: 16px;
  }
  
}
.footer__button-container {
  background-color: white;
  width: 3%;
  margin: 0 auto;
  position: relative;
}

@media screen and (max-width:867px) {
  .footer__button-container{
    width: 10%;
  }
  
}

.footer__button {
  width: 80%;
}




.loader{
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  transition: opacity 3s ease, visibility 0.75s ease;
}


.loader-hidden{
  opacity: 0;
  visibility: hidden;
}

.loader::after{
  content: "";
  width: 75px;
  height: 75px;
  border: 15px solid gainsboro;
  border-top-color: gold;
  border-radius: 50%;
  animation: loading 0.75s ease infinite;
}

@keyframes loading {
  from{
    transform: rotate(0turn);
  }
  to{
    transform: rotate(1turn);
  }
  
}

.whatsapp{
  width: 100%;
  position: fixed;
  right: 50px;
  bottom: 50px;
  display: flex;
  justify-content: flex-end;
  animation: float 3s ease-in-out infinite;
  z-index: 1;
}


.whatsApp__img{
  width: 5%;

}

@media screen and (max-width:867px) {

  .whatsApp__img{
    width: 20%;

  }
  
}


@keyframes float {
  0% {
    transform: translateY(0);
  }
    50%{
      transform: translateY(20px);
    }
    100%{
      transform: translateY(0);
    }
  
}

.reveal{
  position: relative;
  transform: translateY(150px);
  opacity: 0;
    transition: all 2s ease;

}

.reveal.active{
  transform: translateY(0);
  opacity: 1;
}
